import { Body, H2 } from "@casavo/habitat"

import { container, wrapper } from "@app/shared/landingpages/styles.css"

import { textHeading } from "./styles.css"

const textData = [
  {
    body: " We live together, every day. We care about you and value you. We care about your time and the things you love. Here, you are free to choose the work routine that suits you best. Whether you work remotely or from the office, you will always feel part of the Team.",
    title: "Bring your ambition. Bring your desire to innovate.",
  },
  {
    body: "Work has a huge impact on our lives, which is why we want to make sure you have everything you need to enjoy every day at Casavo.",
    title: "Why you should be a part of this",
  },
  {
    body: "We care about you and value what you have to offer. Here you are free to choose the work routine that best suits you. Whether you work remotely or from the office, you will always feel like part of the team.",
    title: "Complete Flexibility? Yes.",
  },
]

export const TextHeadings: React.FC = () => {
  return (
    <section className={wrapper({ bg: "white" })}>
      <div className={container}>
        {textData.map((item, index) => {
          return (
            <hgroup key={index} className={textHeading}>
              <H2>{item.title}</H2>
              <Body size="l">{item.body}</Body>
            </hgroup>
          )
        })}
      </div>
    </section>
  )
}
