import { Button, H2 } from "@casavo/habitat"
import Link from "next/link"

import { wrapper, container } from "@app/shared/landingpages/styles.css"

import { textHeading } from "./styles.css"

export const AboutUs: React.FC = () => {
  return (
    <section className={wrapper({ bg: "white" })}>
      <div className={container}>
        <div className={textHeading}>
          <H2>
            Before applying, ready to uncover what sets us apart? Dive into our story, where innovation,
            passion, and collaboration fuel our success.
          </H2>
          <Link href="/about-us">
            <Button>Learn more about us</Button>
          </Link>
        </div>
      </div>
    </section>
  )
}
