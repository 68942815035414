import { Button, H1 } from "@casavo/habitat"
import Link from "next/link"

import { MyImage } from "@app/shared/components/MyImage"
import { container, wrapper } from "@app/shared/landingpages/styles.css"

import { heroButtons, heroContainer, heroImage, heroText, leftHero, textAlignHero } from "./styles.css"

export const CareersHero: React.FC = () => {
  return (
    <section className={wrapper({ bg: "orange" })}>
      <div className={[container, heroContainer].join(" ")}>
        <div className={leftHero}>
          <div className={heroText}>
            <div className={textAlignHero({ direction: "left" })}>
              <H1 noMargin>Make the difference.</H1>
            </div>
            <div className={textAlignHero({ direction: "right" })}>
              <H1 noMargin>From day 1.</H1>
            </div>
          </div>
          <div className={heroButtons}>
            <Link href="/careers/open-positions">
              <Button>See open positions</Button>
            </Link>
            <Link href="/about-us">
              <Button variant="secondary">What do we offer?</Button>
            </Link>
          </div>
        </div>
        <MyImage alt={""} className={heroImage} height={256} src={`/images/careers/hero.svg`} width={630} />
      </div>
    </section>
  )
}
