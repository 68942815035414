
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from "next"
import { FC } from "react"

import { CareersContent } from "@app/components/careers"
import { Seo } from "@app/components/seo"
import { PageKind } from "@app/shared/types/pages"
import { Meta } from "@app/shared/types/seo"
import * as casavoPages from "@shared/utils/pages"

const Careers: FC<InferGetStaticPropsType<typeof wrappedGetStaticProps>> = ({ seo }) => {
  const metaTags: Meta = {
    description:
      "Do you want to revolutionize the real estate market? Apply and join the Casavo Team, the digital platform that is revolutionizing the way of selling and buying a house.",
    title: "Work with us | Casavo Careers",
  }

  return (
    <>
      <Seo
        canonical="https://casavo.com/en/careers"
        og={{
          description: metaTags.description,
          images: [
            { url: (process.env.NEXT_PUBLIC_IMAGE_PATH || "").concat("/images/seo/casavo-og-image.png") },
          ],
          site_name: "Casavo",
          url: "https://casavo.com/en/careers",
        }}
        {...metaTags}
      />

      <CareersContent />
    </>
  )
}

const wrappedGetStaticProps = async (ctx: GetStaticPropsContext) => {
  if (ctx.locale === casavoPages.DEFAULT_LOCALE) {
    return {
      notFound: true,
      props: {} as never,
    }
  }

  const locales = casavoPages.countryLocales(ctx.locales as Locale[])
  const seoBuilder = casavoPages.seo(process.env.NEXT_PUBLIC_BASE_PATH as string)(PageKind.mortgage)

  const seo = {
    alternates: seoBuilder.alternates(locales),
    canonical: seoBuilder.canonical(ctx.locale as Locale),
  }

  return {
    props: {
      seo,
    },
    revalidate: process.env.NEXT_TARGET_MODE !== "serverless" ? 86400 : undefined,
  }
}

 const _getStaticProps: GetStaticProps = wrappedGetStaticProps

export default Careers


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/careers/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  