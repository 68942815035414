import { Body, Button, H2 } from "@casavo/habitat"
import Link from "next/link"

import { MyImage } from "@app/shared/components/MyImage"
import { container } from "@app/shared/landingpages/styles.css"

import {
  orangeBlock,
  orangeBlockButton,
  orangeBlockHeading,
  orangeCurve,
  wrapperOverflowVisible,
} from "./styles.css"

export const OrangeBlock: React.FC = () => {
  return (
    <section className={wrapperOverflowVisible({ bg: "orange" })}>
      <div className={[container, orangeBlock].join(" ")}>
        <div>
          <hgroup className={orangeBlockHeading}>
            <H2>We're waiting for you</H2>
            <MyImage
              alt=""
              className={orangeCurve}
              height={147}
              src={"/images/careers/curveOrange.svg"}
              width={339}
            />
          </hgroup>
          <Body size="l">
            Do you like what you have read so far? Then, become our housemate: we look forward to getting to
            know you. Choose the Team you would like to work in and learn about our open positions.
          </Body>
        </div>
        <div className={orangeBlockButton}>
          <Link href="/careers/open-positions">
            <Button>See all positions</Button>
          </Link>
        </div>
      </div>
    </section>
  )
}
