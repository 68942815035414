import { Body } from "@casavo/habitat"

import { MyImage } from "@app/shared/components/MyImage"
import { wrapper } from "@app/shared/landingpages/styles.css"

import { benefitContainer, singleBenefit, singleBenefitImage } from "./styles.css"

const benefits = [
  {
    image: `/images/careers/health-insurance.svg`,
    text: "Health Insurance Plan",
  },
  {
    image: `/images/careers/inclusive-team.svg`,
    text: "Inclusive, International Team",
  },
  {
    image: `/images/careers/custom-programs.svg`,
    text: "Customized Learning Programs",
  },
  {
    image: `/images/careers/tailor-made.svg`,
    text: "Tailor-made Growth Path",
  },
  {
    image: `/images/careers/salary.svg`,
    text: "Above market Compensation",
  },
  {
    image: `/images/careers/wellfare.svg`,
    text: "Work Life Balance Initiatives",
  },
  {
    image: `/images/careers/technology.svg`,
    text: "The Best Technology",
  },
  {
    image: `/images/careers/food-voucher.svg`,
    text: "Meal Vouchers",
  },
]

export const Benefits: React.FC = () => {
  return (
    <section className={wrapper({ bg: "white" })}>
      <div className={[benefitContainer].join(" ")}>
        {benefits.map((benefit, index) => {
          return (
            <figure key={index} className={singleBenefit}>
              <MyImage alt={""} className={singleBenefitImage} height={128} src={benefit.image} width={128} />
              <Body size="l">{benefit.text}</Body>
            </figure>
          )
        })}
      </div>
    </section>
  )
}
