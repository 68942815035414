import { Body, Button } from "@casavo/habitat"
import Link from "next/link"
import { useState } from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { container, wrapper } from "@app/shared/landingpages/styles.css"

import { desktopHeader, headerButtons, mobileBurgerMenu, mobileMenu, mobileMenuItem } from "./styles.css"

export const CareersHeader: React.FC = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  return (
    <section className={wrapper({ bg: "orange" })}>
      <div className={container}>
        <div className={desktopHeader}>
          <MyImage
            alt={"Casavo careers logo"}
            height={68}
            src={"/images/careers/casavo-careers.svg"}
            width={180}
          />
          <div className={headerButtons}>
            <Link href="/about-us">
              <Body noMargin>About us</Body>
            </Link>
            <Link href="/careers/open-positions">
              <Button>See open positions</Button>
            </Link>
          </div>
          <div
            className={mobileBurgerMenu}
            onClick={() => {
              setHamburgerOpen((prevState) => !prevState)
            }}
          >
            <MyImage
              alt={"closed-burger-menu"}
              height={24}
              src={
                hamburgerOpen ? "/images/careers/closed-burger-menu.svg" : "/images/careers/burger-menu.svg"
              }
              width={24}
            />
          </div>
        </div>

        {hamburgerOpen && (
          <div className={mobileMenu}>
            <ul>
              <li className={mobileMenuItem}>
                <Link href="/about-us">
                  <Body noMargin size="l">
                    About us
                  </Body>
                </Link>
              </li>
              <li className={mobileMenuItem}>
                <Link href="/careers/open-positions">
                  <Body noMargin size="l">
                    See open positions
                  </Body>
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </section>
  )
}
