import { Footer } from "@app/shared/components/layout/Footer"

import { AboutUs } from "./AboutUs"
import { Benefits } from "./Benefits"
import { CareersHeader } from "./CareersHeader"
import { CareersHero } from "./CareersHero"
import { OrangeBlock } from "./OrangeBlock"
import { TextHeadings } from "./TextHeadings"

export const CareersContent: React.FC = () => {
  return (
    <>
      <CareersHeader />
      <CareersHero />
      <TextHeadings />
      <Benefits />
      <OrangeBlock />
      <AboutUs />
      <Footer />
    </>
  )
}
